import React from 'react'
import './TermsAndCond.scss'

const TermsAndCond = () => {
  return (
    <div className="terms-and-cond-page">
      <div className="title">Termeni si conditii</div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas enim eros, euismod ac nunc in, lacinia pulvinar lectus. Quisque finibus, est et tristique tincidunt, felis metus cursus ex, placerat cursus nisi sem sit amet ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus ut augue consectetur, mattis arcu nec, ultrices metus. Vestibulum feugiat non lorem interdum rutrum. Praesent feugiat lacus metus. Vivamus consectetur rutrum mi, quis ornare leo malesuada et. Nullam suscipit metus ut ligula dapibus, eget imperdiet ante iaculis. Aliquam non leo turpis. Integer porttitor fermentum nulla. Fusce et gravida mi. Aenean malesuada, massa a aliquet egestas, enim purus malesuada neque, ac venenatis velit lacus sed quam. Ut venenatis, risus sit amet mollis tincidunt, est sapien sollicitudin mauris, et posuere nisl tellus et felis. Donec lobortis ligula nec felis consectetur auctor.
        <br></br>
        Nam lacinia elit eget dui eleifend sollicitudin. Nunc augue massa, malesuada a elit sed, ornare bibendum orci. In aliquam pellentesque enim, eu rutrum velit sagittis vestibulum. Donec non sagittis nisi. Curabitur scelerisque felis nibh, eu feugiat urna accumsan vel. Duis at arcu lectus. Pellentesque ut pharetra metus, vitae facilisis eros. Proin mattis leo vitae justo congue, eu sagittis magna ornare. Sed orci lacus, vehicula et augue eu, dignissim euismod nunc.
        <br></br>
        Donec egestas augue ligula, vel sagittis ex blandit nec. Fusce ut lobortis felis, quis varius justo. Morbi tristique, ligula viverra euismod porttitor, metus neque venenatis massa, eu rhoncus odio leo eget nunc. Nulla vitae volutpat nibh. Proin ac ipsum nec purus pretium rhoncus id malesuada odio. Pellentesque vitae velit nisl. Praesent pellentesque, tortor at venenatis tincidunt, augue enim ornare ligula, nec porttitor ex mauris a leo. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
        <br></br>
        Mauris velit sem, tincidunt sodales lorem sed, venenatis scelerisque sem. Curabitur posuere cursus libero quis mollis. Integer vel libero quis leo posuere auctor. Cras id mi euismod, egestas ante et, condimentum urna. Morbi ac massa id sem consectetur maximus sed id augue. Aliquam sit amet tellus at tortor molestie blandit. Fusce sollicitudin vitae magna vitae ornare. Etiam imperdiet leo vitae laoreet facilisis. Maecenas blandit leo vitae feugiat laoreet. Integer justo ante, euismod et felis at, luctus consectetur nunc.
        <br></br>
        Pellentesque sit amet felis eget mi ultrices feugiat commodo scelerisque arcu. Curabitur facilisis ligula quis venenatis hendrerit. Duis hendrerit nibh sed libero pulvinar, a faucibus sem commodo. Duis laoreet maximus felis, eget consectetur sem elementum vulputate. Proin a eleifend leo, vel euismod nulla. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Duis et tortor dolor. Morbi consectetur nisi arcu, ut ultricies metus bibendum condimentum. Vestibulum vitae sapien id est malesuada posuere. Vivamus ultrices velit non magna posuere varius eget non sapien. Sed porttitor odio quis velit auctor, ut ullamcorper arcu luctus. Ut a dui non nunc tincidunt fringilla ac accumsan diam.
        <br></br>
        Etiam viverra felis ac turpis dictum, in facilisis urna pulvinar. Sed in elit ac ipsum rutrum ornare. Integer tristique mattis erat eget tristique. Nullam tincidunt non turpis at dapibus. Curabitur gravida ut purus quis sodales. Quisque lobortis mollis nisi at tincidunt. Nunc consectetur orci eros, convallis mattis massa mattis eget. Proin sit amet diam ac tellus vehicula vulputate sit amet vel nulla. Phasellus nec posuere arcu, sit amet posuere nibh. Donec ornare lectus sem, vel rutrum urna placerat aliquam. Aliquam rhoncus, elit vitae imperdiet ultricies, massa velit sollicitudin nisl, non hendrerit leo enim molestie sapien. Donec in nulla viverra, feugiat orci non, pharetra felis.
        <br></br>
        Aenean lectus nibh, varius id orci a, dapibus auctor elit. Vivamus eu commodo eros. Proin iaculis nulla ultrices, fringilla eros at, blandit tortor. Pellentesque at nibh eget eros dictum porta a non turpis. Aenean vitae aliquam felis. Proin ac dolor eu metus fringilla porttitor eu in lectus. Curabitur sollicitudin fermentum dui, id varius tellus auctor nec. Proin id ultrices ante. Maecenas metus lorem, laoreet in nunc non, euismod commodo velit.
        <br></br>
        Praesent viverra aliquet nibh vitae ornare. Praesent eget tempus orci. Phasellus non consectetur enim. Nulla ut arcu blandit, congue sem nec, sodales nibh. Donec pellentesque erat id nisi fermentum, vitae fermentum nibh bibendum. Nam quis est justo. Integer tristique ex rutrum leo porta, vel faucibus mi posuere. Vestibulum velit felis, interdum in turpis interdum, laoreet commodo sem. Fusce at faucibus diam. Nulla consequat, nibh at congue venenatis, lectus risus finibus metus, a gravida odio magna non leo.
        <br></br>
        Aliquam malesuada, mi sit amet volutpat ultricies, est nisi tempor urna, quis condimentum lorem risus ac libero. Aliquam sit amet nulla mollis, tristique sapien sed, consequat purus. Quisque a facilisis libero. Duis iaculis porta tortor vitae laoreet. Sed ac sodales magna. Maecenas orci lectus, semper et ante et, volutpat sagittis neque. Duis vehicula efficitur iaculis. Morbi tincidunt lectus eu metus commodo fringilla. Mauris hendrerit, velit ac vestibulum imperdiet, nunc tortor faucibus orci, quis malesuada mauris orci quis est. Proin ut leo mi. Fusce non semper lorem. Cras at risus at ante tristique rhoncus. Fusce velit ligula, pellentesque nec leo eget, pulvinar pulvinar enim.
        <br></br>
        Curabitur mattis ac neque quis pulvinar. Curabitur id odio in ipsum varius pulvinar. Mauris lorem lectus, pulvinar ac commodo quis, mollis eu tellus. Quisque varius felis id diam blandit interdum. Ut quis sodales eros. Mauris tempor dapibus ligula. Donec laoreet mattis augue ut dictum. In sollicitudin ultrices molestie. Integer dignissim, sem quis sodales tempus, orci sapien cursus augue, quis aliquam velit orci at ligula. Morbi egestas hendrerit sem, ac convallis augue ornare quis.
        <br></br>
        In congue condimentum ligula. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Quisque venenatis sem cursus libero porta, sed tincidunt nisl mollis. Donec interdum, ex quis feugiat consequat, ex nisi pharetra nisl, pellentesque euismod felis metus non mauris. Aenean ex urna, tempus et diam sit amet, sagittis porttitor felis. Quisque non magna tellus. Maecenas volutpat eget ligula ut gravida. Donec at massa non urna facilisis blandit et in lorem. Nullam sed sollicitudin massa. Curabitur leo ipsum, convallis sit amet malesuada in, sodales vel tellus. Pellentesque vestibulum pulvinar enim sed lacinia. In consectetur lorem id turpis ultricies aliquam. Sed bibendum, tortor quis eleifend sagittis, orci ipsum ultricies felis, nec commodo urna ex ac nisl.
        <br></br>
        Vivamus tincidunt ornare libero, id consequat mi hendrerit ac. Sed sit amet elit nulla. Nam ac ex ut elit condimentum sollicitudin. Morbi tincidunt maximus elit. Proin consectetur euismod enim id scelerisque. Nam vitae auctor dolor. Praesent feugiat sollicitudin dui nec vestibulum.
        <br></br>
        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Pellentesque ut maximus diam, eget placerat neque. Nunc vel dolor a justo sodales bibendum sed sit amet diam. Quisque et tempus lacus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras sollicitudin non metus et hendrerit. Duis accumsan nibh eu nisl aliquet feugiat.
        <br></br>
        Nunc quis commodo sapien, ac volutpat nulla. Etiam id neque molestie, lacinia eros pulvinar, sodales sapien. Mauris luctus diam nec est tincidunt, sit amet mollis lorem cursus. Ut venenatis tincidunt elit. Cras elementum vitae ex ac molestie. In non commodo massa, eu molestie elit. Fusce porta vulputate interdum. In hac habitasse platea dictumst. Ut vel quam ligula. Sed dapibus ligula sem, at convallis quam aliquam fermentum. Nullam condimentum placerat enim ut commodo. Suspendisse feugiat lectus lacus, at laoreet risus condimentum eget. Nam pharetra lectus augue, eu fringilla velit tristique non. Duis viverra lectus quis leo efficitur, non tempor dolor sollicitudin.
        <br></br>
        Aenean maximus ullamcorper lectus, non hendrerit odio auctor nec. Cras consectetur velit dolor, auctor sodales nunc tincidunt a. Nunc vitae tempor orci. Fusce purus lorem, fermentum eget lacinia non, lacinia et arcu. Mauris imperdiet est nisl, sit amet dignissim mauris placerat in. Donec auctor dapibus enim, in bibendum nisi sagittis pulvinar. Nam risus nunc, ornare ut nunc non, placerat gravida lectus. Praesent condimentum, justo ultrices fringilla tristique, purus metus porta tellus, vitae cursus urna mauris volutpat felis. Nam non iaculis elit, vitae rhoncus nunc.
      </p>
    </div>
  )
}

export default TermsAndCond
